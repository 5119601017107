<template>
  <div>
    <div v-show="isEditable">
      <flat-pickr
        v-model="datetime"
        :config="config"          
        :placeholder="$gfStrings.placeHolder.datetime"
        class="form-control"
        @on-close="sendUpdate"
      />
    </div>
    <span v-if="!isEditable">
      {{ parseForDisplay }}
    </span>
  </div>
</template>

<script>
import MetaFieldLoaderMixin from '../MetaFieldLoaderMixin.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
  name: 'DatePicker',
  components: {
        flatPickr
  },
  mixins:[MetaFieldLoaderMixin],
  props:{
        includeTime: {
            type: Boolean,
            default: false
        },
        minDate: {
            type: String,
            default: null
        },
        maxDate: {
            type: String,
            default: () => new Date().toISOString()
        },
        format: {
            type: String,
            default:  "Y/m/d h:i K"
        }
  },
  data() {
        return {
            datetime: '',
            config: {wrap:true, enableTime: true, dateFormat:'Y/m/d h:i K', static: true}
        };
    },
    computed:{
      parseForDisplay() {
            if(!moment(this.datetime).isValid())
                return null;

            if(this.displayModeOptions && this.displayModeOptions.dateFormat){             
              return moment(this.datetime).format(this.displayModeOptions.dateFormat);
            }
            return this.datetime;
          }
    },
  watch: {
    datetime(val) {
      this.updatedValue = this.parseForData(val);
    },

    updatedValue(val) {
      this.datetime = this.parseForPicker(val);
    }
  },

  methods: {
    parseForData(val) {
      if(!moment(val).isValid())
          return null;

      var momentVal = moment(val,'YYYY/MM/DD hh:mm A');
      return momentVal.format('DD/MM/YYYY hh:mm:ss A Z').toString();
    },

    parseForPicker(val) {
      var momentVal = moment(val,'DD/MM/YYYY hh:mm:ss A Z');
      
      return momentVal.format('YYYY/MM/DD h:mm A').toString();
    }
  }
  
}
</script>
