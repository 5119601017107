<template>
  <div>
    <div v-if="isEditable">
      <input
        v-model="updatedValue"
        type="text"
        @blur="sendUpdate($event)"
      >
    </div>
    <span
      v-if="!isEditable"
    >
      {{ updatedValue }}
    </span>
  </div>
</template>

<script>
import MetaFieldLoaderMixin from '../MetaFieldLoaderMixin.vue'
export default {
  name: 'TextBox',
  mixins:[MetaFieldLoaderMixin],
}
</script>
