<script>
export default {
  name: "MetaFieldLoaderMixin",
  props: {
    value: {
      type: String,
      default: "",
    },
    valueFormat: {
      type: String,
      default: "",
    },
    editModeOptions: {
      type: Object,
      default: () => {},
    },
    displayModeOptions: {
      type: Object,
      default: () => {},
    },
    hasValidation: {
      type: Boolean,
      default: false,
    },
    messageError: {
      type: String,
      default: "",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updatedValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.updatedValue = val;
    },
  },
  mounted() {
    this.$root.$on("cancel", () => {
      this.updatedValue = this.value;
    });
  },

  methods: {
    sendUpdate(event) {
      this.$emit("update-meta-field", this.updatedValue);
    },
    resetValue(val) {
      this.updatedValue = val;
    },
  },
};
</script>
