<template>
  <div class="row meta-field">
    <div class="col-md-6 editable">
      <component
        :is="getComponent()"
        v-click-outside="vcoConfig"
        :value="currentValue"
        :value-format="valueFormat"
        :edit-mode-options="editModeOptions"
        :display-mode-options="displayModeOptions"
        :is-editable="editMode"
        @click.native="edit"
        @update-meta-field="update($event)"
      />
    </div>
    <div
      v-if="editMode && isEditable"
      class="col-md-6 text-right cancel-button"
      @mousedown="cancel"
    >
      <div
        id="metaVisibility"
        class="show-public mx-2 d-inline-block"
        :class="`meta-visibility-${metaId}`"
      >
        <span>{{ $gfStrings.show }}: </span>
        <input
          :checked="inputIsPublic"
          type="checkbox"
          @change="checkUpdate($event)"
        >
      </div>
      <div class="cancel-edit meta-edit mx-2 d-inline-block">
        {{ $gfStrings.cancel }}
      </div>
    </div>
    <div
      v-if="!editMode && isEditable"
      class="col-md-6"
    >
      <span
        class="fa fa-edit edit-detail meta-field-edit"
        :class="`meta-field-edit-${metaId}`"
        @click="edit"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VueLoggerPlugin } from "@gf/gf-logger";
import DatePicker from "./DatePicker/DatePicker.vue";
import TextBox from "./TextBox/TextBox.vue";
import { GfVueI18nPlugin, fundraisingPageComponent } from "@gf/gf-localisation";
import gfApiServices from "@gf/gf-api-services";
import componentLookup from "./componentLookup";
import vClickOutside from "v-click-outside";

Vue.use(GfVueI18nPlugin, {
  messages: fundraisingPageComponent,
  locale: "en_au",
});
Vue.use(VueLoggerPlugin);
Vue.use(vClickOutside);

export default {
  name: "MetaFieldLoader",
  components: {
    DatePicker,
    TextBox,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    fundraisingPageId: {
      type: Number,
      default: 0,
    },
    metaId: {
      type: Number,
      default: 0,
    },
    metaType: {
      type: String,
      default: "",
    },
    valueFormat: {
      type: String,
      default: "",
    },
    editModeOptions: {
      type: Object,
      default: () => {},
    },
    displayModeOptions: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    baseDomain: {
      type: String,
      default: "",
    },
    demo: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      inputIsPublic: this.isPublic,
      currentValue: "",
      originalIsPublicValue: this.isPublic,
      vcoConfig: {
        middleware: this.clickedOutsideMiddleware,
        handler: this.makeUpdateRequest,
        events: ["dblclick", "click"],
      },
    };
  },
  mounted() {
    Vue.use(gfApiServices, { logger: this.$gfLogger, apiDomain: "" });
    this.currentValue = this.value;
  },
  methods: {
    getComponent: function () {
      return componentLookup.identifier[this.metaType];
    },
    edit: function () {
      this.editMode = this.isEditable;
    },
    cancel: function (evt) {
      if (
        evt.target &&
        evt.target.parentElement &&
        evt.target.parentElement.id === "metaVisibility"
      )
        return;
      this.editMode = false;
      this.inputIsPublic = this.originalIsPublicValue;

      this.$root.$emit("cancel");
    },

    clickedOutsideMiddleware(event) {
      if (event.target.className.includes(`meta-field-edit-${this.metaId}`))
        return false;
      if (
        event.target.parentElement !== null &&
        event.target.parentElement.className.includes(
          `meta-visibility-${this.metaId}`
        )
      ) {
        return false;
      }

      return true;
    },

    async makeUpdateRequest() {
      if (this.editMode) {
        this.editMode = false;
        let result = await this.$gfApiServices.pagesService.updatePageMeta({
          Id: this.metaId,
          FundraisingPageId: this.fundraisingPageId,
          IsPublic: this.inputIsPublic,
          Value: this.currentValue,
        });
        this.currentValue = result.data.Value;
        this.originalIsPublicValue = result.data.IsPublic;
      }
    },
    async update(val) {
      this.currentValue = val;
      this.makeUpdateRequest();
    },
    async checkUpdate(val) {
      this.inputIsPublic = val.target.checked;
    },
  },
};
</script>
